<template>
  <v-container>
    <h2 class="b-4">{{ faqName }}</h2>
    <div v-if="content != null">
      <v-expansion-panels
        class="faq-list"
        accordion
        :value="panels"
        :multiple="multiple"
      >
        <v-expansion-panel
          class="rounded mt-3"
          v-for="item in content"
          :key="item.id"
        >
          <v-expansion-panel-header>
            <p>
              <strong v-html="item.title.rendered" @click="clicked"></strong>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.content.rendered" @click="clicked"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-btn
      :title="$t('navbar.faq.backBtn')"
      class="back-button mt-6"
      large
      color="primary"
      depressed
      :to="{ name: 'FaqCategory' }"
    >
      {{ $t("navbar.faq.backBtn") }}
    </v-btn>
  </v-container>
</template>
<script>
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "FaqList",
  mixins: [clickHandler],
  data() {
    return {
      content: null,
      pageData: null,
      panels: [],
      multiple: false
    };
  },
  computed: {
    faqName() {
      return this.pageData ? this.pageData.name : "";
    }
  },
  jsonld() {
    if (this.content) {
      const items = this.content.map(item => ({
        "@type": "Question",
        name: item.title.rendered,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.content.rendered
        }
      }));
      return {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        mainEntity: items
      };
    }
  },
  mounted() {
    this.fetchFaqList(this.$route.params.categoryName);
    this.fetchPageData(this.$route.params.categoryName);
  },
  methods: {
    async fetchFaqList(categoryName) {
      const res = await CMSService.getCustomPostByFilters("faq", [
        {
          filterName: "filter[categoria_faq]",
          filterValue: categoryName
        }
      ]);

      if (res && res.data && res.data.length) {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].open && res.data[i].open == "1") {
            this.panels.push(i);
            this.multiple = true;
          }
        }
        this.content = res.data;
      }
    },
    async fetchPageData(categoryName) {
      const result = await CMSService.getCustomPostBySlug(
        "categoria_faq",
        categoryName
      );
      if (result && result.data && result.data.length) {
        this.pageData = result.data[0];
      }
    }
  }
};
</script>
<style scoped>
img.size-full {
  width: 100%;
}
.v-expansion-panel-header {
  padding: 16px 28px;
  font-size: 16px;
}
.v-expansion-panel-content__wrap {
  padding: 0 28px 16px;
}
</style>
